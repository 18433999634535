<template>
  <section class='section'>
    <h1 style="padding-left: 0; padding-bottom: 0;">Productos activos: {{ stockData }}</h1>
    <nav class="level is-vcentered">
      <div class="level-left">
        <div class="level-item">
          <h1 style="padding-left: 0; padding-bottom: 15px;">
            <span v-if="date && !showDatePicker">Datos desde el inicio</span>
            <span v-else>Datos desde : </span>
          </h1>
          <button @click="toggleDatePicker" v-if="!showDatePicker" class="button">
            Filtrar por fecha
          </button>
          <div v-else class="level-item">
            <v-date-picker
              mode='range'
              v-model='date'
              :max-date="today"
            />
          </div>
          <button @click="resetDate" v-if="showDatePicker" class="button">
            <span class="material-icons">
              restart_alt
            </span>
          </button>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-8">
        <stadistics :dateSegmentation="date" @stockData="handleDataFromChild" />
        <charts :dateSegmentationGraph="date" :shouldShowCharts="shouldShowCharts" />
      </div>
      <div class="column is-4">
        <div class="box sticky">
          <timeline />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    const today = moment().startOf('day')
    const yesterday = moment(today).subtract(1, 'days')
    const startDate = moment(yesterday).subtract(6, 'days')

    return {
      date: { start: startDate.toDate(), end: yesterday.toDate() },
      today: moment().add(1, 'days').format('YYYY-MM-DD'),
      showDatePicker: true,
      stockData: 0
    }
  },
  computed: {
    shouldShowCharts () {
      if (this.date?.start && this.date?.end) {
        const startDate = moment(this.date.start).format('YYYY-MM-DD')
        const endDate = moment(this.date.end).format('YYYY-MM-DD')
        return startDate !== endDate
      }
      return false
    }
  },
  methods: {
    toggleDatePicker () {
      this.showDatePicker = !this.showDatePicker
    },
    resetDate () {
      this.date = {}
      this.showDatePicker = false
    },
    handleDataFromChild (data) {
      this.stockData = data
    }
  },
  components: {
    Stadistics: () => import('@/components/Dashboard/Stadistics'),
    Timeline: () => import('@/components/Dashboard/Timeline'),
    Charts: () => import('@/components/Dashboard/Charts')
  }
}
</script>

<style scoped>
.section {
  width: 100%;
  padding: 0 20px 0 20px;
}
.sticky {
  position: sticky;
  width: 100%;
  top: 20px;
}
.material-icons {
  cursor: pointer;
}
</style>
